import { checkNumbers, checkWhitespaces } from "../../Utils/Common"
import { handleValidationChange } from "../CommonValidation"

export const validateLogin = (data, setIsError) => {
  var isValid = true

  if (data.ecard_no === "") {
    handleValidationChange("ecard_no", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("ecard_no", false, setIsError)
  }

  if (data.password === "") {
    handleValidationChange("password", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("password", false, setIsError)
  }

  return isValid
}

export const validateCreateAccountPrimary = (data, setIsError) => {
  var isValid = true

  if (data.ecard_no === "" || checkWhitespaces(data.ecard_no)) {
    handleValidationChange("ecard_no", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("ecard_no", false, setIsError)
  }

  if (data.name === "" || checkWhitespaces(data.name)) {
    handleValidationChange("name", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("name", false, setIsError)
  }

  if (data.contact_no === "" || !checkNumbers(data.contact_no)) {
    handleValidationChange("contact_no", true, setIsError)
    handleValidationChange("contact_no_length", false, setIsError)
    isValid = false
  } else {
    handleValidationChange("contact_no", false, setIsError)
    if (data.contact_no.length !== 10) {
      handleValidationChange("contact_no_length", true, setIsError)
      isValid = false
    } else {
      handleValidationChange("contact_no_length", false, setIsError)
    }
  }

  return isValid
}
export const validateCreateAccount = (data, setIsError) => {
  var isValid = true

  if (data.password === "" || checkWhitespaces(data.password)) {
    handleValidationChange("password", true, setIsError)
    handleValidationChange("confirm_password", false, setIsError)
    handleValidationChange("password_length", false, setIsError)
    isValid = false
  } else {
    handleValidationChange("password", false, setIsError)
    if (data.password.length < 6) {
      handleValidationChange("password_length", true, setIsError)
      isValid = false
    } else {
      handleValidationChange("password_length", false, setIsError)
      if (data.password !== data.confirm_password) {
        handleValidationChange("confirm_password", true, setIsError)
        isValid = false
      } else {
        handleValidationChange("confirm_password", false, setIsError)
      }
    }
  }

  return isValid
}
export const validateCheckAccount = (data, setIsError) => {
  var isValid = true

  if (data.ecard_no === "") {
    handleValidationChange("ecard_no", true, setIsError)
    isValid = false
  } else {
    handleValidationChange("ecard_no", false, setIsError)
  }

  if (data.mobile_no === "" || !checkNumbers(data.mobile_no)) {
    handleValidationChange("mobile_no", true, setIsError)
    handleValidationChange("mobile_no_length", false, setIsError)
    isValid = false
  } else {
    handleValidationChange("mobile_no", false, setIsError)
    if (data.mobile_no.length !== 10) {
      handleValidationChange("mobile_no_length", true, setIsError)
      isValid = false
    } else {
      handleValidationChange("mobile_no_length", false, setIsError)
    }
  }

  return isValid
}
