import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faAngleDoubleRight,
  faArrowLeft,
  faCheckToSlot,
  faChevronDown,
  faDownload,
  faEye,
  faEyeSlash,
  faLock,
  faMagnifyingGlass,
  faPlus,
  faSquarePlus,
  faUser,
  faWallet,
} from "@fortawesome/free-solid-svg-icons"

library.add(
  faUser,
  faLock,
  faDownload,
  faEye,
  faEyeSlash,
  faChevronDown,
  faAngleDoubleRight,
  faWallet,
  faCheckToSlot,
  faPlus,
  faSquarePlus,
  faArrowLeft,
  faMagnifyingGlass
)
