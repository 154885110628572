import { getKey, getToken, getUser } from "../Utils/Common"
import { getAPICall, postAPICall } from "./axiosMethodCalls"

export const getProducts = async (promo_id, data) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "promos/get",
      {
        requester: getUser(),
        token: getToken().replace(/['"]+/g, ""),
        promo_id: promo_id,
        category:
          data.category?.toLowerCase() === "all"
            ? ""
            : data.category?.toLowerCase(),
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const getSingleProduct = async (promo_id) => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "promos/get",
      {
        requester: getUser(),
        api_key: getKey().replace(/['"]+/g, ""),
        token: getToken().replace(/['"]+/g, ""),
        promo_id: promo_id,
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}
