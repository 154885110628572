import React, { useState } from "react"
import { toast } from "react-toastify"

import { Row, Col, Form, InputGroup } from "react-bootstrap"

import { useNavigate } from "react-router-dom"
import InputError from "../../Components/InputError/InputError"

export default function OTP() {
  const navigate = useNavigate()
  const [disableClick, setDisableClick] = useState(false)
  const [isCreated, setIsCreated] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  const [data, setData] = useState({
    agent_id: "",
    // name: '',
    first_name: "",
    last_name: "",
    birthdate: "",
    contact_no: "",
    email_address: "",
    address: "",
  })

  const [isError, setIsError] = useState({
    agent_id: false,
    first_name: false,
    last_name: false,
    birthdate: false,
    contact_no: false,
    address: false,
  })

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }

  function handleChange(e) {
    let newData = { ...data }
    const { name, value } = e.target
    newData[name] = value
    setData(newData)
  }

  // async function handleCreate() {
  //   if (validateCreateAccount(data, setIsError) && !disableClick) {
  //     setDisableClick(true)
  //     const response = await createAccount(data)
  //     if (response.data) {
  //       setTimeout()
  //     } else {
  //       setDisableClick(false)
  //       toast.error(response.error.data.messages.error_messages[0])
  //     }
  //   }
  // }

  return (
    <div className="row login-container bg-dashboard">
      <div className="content-container">
        <Row className=" mt-5 justify-content-center">
          <Col xs={12} md={6} className="p-5 mt-5">
            <Row className="forgot-password">
              <Col md={12}>
                <div className="forgot-label">Enter Code</div>
                <hr />{" "}
                <small>
                  We sent a 6-digit code to another device where you're logged
                  in.
                  <br />
                  Enter the code below.
                </small>
                <Form.Group className="mt-3 text-center mt-5">
                  {/* <Form.Label className="h6" htmlFor="name">
                  Mobile Number
                </Form.Label> */}
                  <h5>4-digit Code</h5>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      name="agent_id"
                      className="form-control"
                      onChange={handleChange}
                    />
                    <InputError
                      isValid={isError.agent_id}
                      message={"This field is required"}
                    />
                    <Form.Control
                      type="text"
                      name="agent_id"
                      className="form-control"
                      onChange={handleChange}
                    />
                    <InputError
                      isValid={isError.agent_id}
                      message={"This field is required"}
                    />
                    <Form.Control
                      type="text"
                      name="agent_id"
                      className="form-control"
                      onChange={handleChange}
                    />
                    <InputError
                      isValid={isError.agent_id}
                      message={"This field is required"}
                    />
                    <Form.Control
                      type="text"
                      name="agent_id"
                      className="form-control"
                      onChange={handleChange}
                    />
                    <InputError
                      isValid={isError.agent_id}
                      message={"This field is required"}
                    />
                  </InputGroup>
                </Form.Group>
                <Row className="justify-content-end mt-5">
                  <Col md={3}>
                    {" "}
                    <button
                      type="submit"
                      className={"login-btn code outlined"}
                      onClick={() => navigate("/login")}
                    >
                      Resend Code
                    </button>
                  </Col>
                  <Col md={3}>
                    {" "}
                    <button
                      type="submit"
                      className={"login-btn code"}
                      onClick={() => navigate("/login")}
                    >
                      Continue
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  )
}

// import React, { useState, useEffect } from "react"
// import { ToastContainer, toast } from "react-toastify"
// import ReactLoading from "react-loading"
// import { Row, Col, Container, Form, InputGroup } from "react-bootstrap"
// import { createAccount } from "../../Helpers/API/CreateAccountApi/CreateAccountApi"
// import { validateCreateAccount } from "../../Helpers/Validations/CreateAccountValidation/CreateAccountValidation"
// import InputError from "../../Components/InputError/InputError"
// import patientLogin from "../../Assets/Login/patientLogin.png"
// import PatientCareProgram from "../../Components/PatientCareProgram/PatientCareProgram"
// import CelebrationIcon from "@mui/icons-material/Celebration"
// import "../Login/Login.css"
// import "../../Components/Forms/Forms.css"
// import { useNavigate } from "react-router-dom"

// export default function OTP() {
//   const navigate = useNavigate()
//   const [disableClick, setDisableClick] = useState(false)
//   const [isCreated, setIsCreated] = useState(false)
//   const [isChecked, setIsChecked] = useState(false)

//   const [data, setData] = useState({
//     agent_id: "",
//     // name: '',
//     first_name: "",
//     last_name: "",
//     birthdate: "",
//     contact_no: "",
//     email_address: "",
//     address: "",
//   })

//   const [isError, setIsError] = useState({
//     agent_id: false,
//     first_name: false,
//     last_name: false,
//     birthdate: false,
//     contact_no: false,
//     address: false,
//   })

//   const handleCheckboxChange = () => {
//     setIsChecked(!isChecked)
//   }

//   function handleChange(e) {
//     let newData = { ...data }
//     const { name, value } = e.target
//     newData[name] = value
//     setData(newData)
//   }

//   async function handleCreate() {
//     if (validateCreateAccount(data, setIsError) && !disableClick) {
//       setDisableClick(true)
//       const response = await createAccount(data)
//       if (response.data) {
//         setTimeout()
//       } else {
//         setDisableClick(false)
//         toast.error(response.error.data.messages.error_messages[0])
//       }
//     }
//   }

//   return (
//     <div className="row login-container">
//       <ToastContainer className={"toast-container pink-bg"} />
//       <PatientCareProgram />
//       <Row className="">
//         <Col xs={12} sm={7} className="login-left-side p-3 mt-5">
//           <Container className="px-4 py-2 welcome-container">
//             <Row className="login-header-1">Welcome To</Row>
//             <Row className="login-header-2 p-0">MedEthix</Row>
//             <Row className="justify-content-center">
//               <img src={patientLogin} alt={"login-img"} className="login-img" />
//             </Row>
//           </Container>
//         </Col>
//         <Col
//           xs={12}
//           sm={5}
//           className="login-right-side justify-content-end center-container"
//         >
//           {!isCreated ? (
//             <Container className="login-form-container">
//               <Row className="justify-content-start gray text-left">
//                 <Col style={{ textAlign: "left" }}>
//                   <p>
//                     Thank you for providing your phone number! <br />
//                     We've just sent a One-Time Password (OTP) to the number you
//                     entered. Please check your messages or SMS inbox for the
//                     OTP.
//                   </p>
//                 </Col>
//               </Row>
//               <Row className="mt-4 text-start">
//                 <Form.Label className="h6" htmlFor="name">
//                   OTP
//                 </Form.Label>
//                 <Col xs={3}>
//                   <InputGroup className="mb-3">
//                     <Form.Control
//                       type="number"
//                       name="agent_id"
//                       className="form-control"
//                       onChange={handleChange}
//                     />
//                   </InputGroup>
//                 </Col>
//                 <Col xs={3}>
//                   <InputGroup className="mb-3">
//                     <Form.Control
//                       type="number"
//                       name="agent_id"
//                       className="form-control"
//                       onChange={handleChange}
//                     />
//                   </InputGroup>
//                 </Col>
//                 <Col xs={3}>
//                   <InputGroup className="mb-3">
//                     <Form.Control
//                       type="number"
//                       name="agent_id"
//                       className="form-control"
//                       onChange={handleChange}
//                     />
//                   </InputGroup>
//                 </Col>
//                 <Col xs={3}>
//                   <InputGroup className="mb-3">
//                     <Form.Control
//                       type="number"
//                       name="agent_id"
//                       className="form-control"
//                       onChange={handleChange}
//                     />
//                   </InputGroup>
//                 </Col>
//               </Row>

//               <Row className="text-start mt-3">
//                 <Col>
//                   Did'nt receive the OTP?{" "}
//                   <strong
//                     style={{ textDecoration: "underline", cursoe: "pointer" }}
//                   >
//                     Resend now.
//                   </strong>
//                 </Col>
//               </Row>
//               <Row className="mt-2 d-flex justify-content-center">
//                 <Col xs={9} className="d-flex justify-content-center">
//                   {disableClick === true ? (
//                     <ReactLoading
//                       type="spinningBubbles"
//                       color="var(--secondary-color)"
//                     />
//                   ) : (
//                     <button
//                       type="submit"
//                       disabled={!isChecked}
//                       className={isChecked ? "login-btn" : "login-btn disabled"}
//                       onClick={handleCreate}
//                     >
//                       SUBMIT
//                     </button>
//                   )}
//                 </Col>
//               </Row>
//             </Container>
//           ) : (
//             <Container className="success-cont">
//               <div className="">
//                 <CelebrationIcon className="success-logo" />
//               </div>
//               <Row className="justify-content-center success-text">
//                 Account created successfully
//               </Row>
//             </Container>
//           )}
//         </Col>
//       </Row>
//     </div>
//   )
// }
