import { getKey, getToken, getUser } from "../Utils/Common"
import { getAPICall, postAPICall } from "./axiosMethodCalls"

export const checkUser = async (data) => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "users/forgot_password",
      {
        ...data,
        contact_no: data.mobile_no,
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const resetPassword = async (data) => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "users/reset_password",
      {
        ...data,
        user_id: getUser(),
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}
