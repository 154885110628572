import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"

import { Row, Col, Form, InputGroup } from "react-bootstrap"

import { useNavigate, useParams } from "react-router-dom"
import InputError from "../../Components/InputError/InputError"
import NavbarTop from "../NavbarTop/NavbarTop"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import BackButton from "../../Components/Back/BackButton"
import { getProducts, getsingleProduct } from "../../Helpers/API/productsAPI"

export default function ViewProduct() {
  const { productID } = useParams()
  const [details, setDetails] = useState({})
  const navigate = useNavigate()
  const [disableClick, setDisableClick] = useState(false)
  const [isCreated, setIsCreated] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  const [data, setData] = useState({
    agent_id: "",
    // name: '',
    first_name: "",
    last_name: "",
    birthdate: "",
    contact_no: "",
    email_address: "",
    address: "",
  })

  const [isError, setIsError] = useState({
    agent_id: false,
    first_name: false,
    last_name: false,
    birthdate: false,
    contact_no: false,
    address: false,
  })

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }

  function handleChange(e) {
    let newData = { ...data }
    const { name, value } = e.target
    newData[name] = value
    setData(newData)
  }

  async function fetchProduct() {
    const response = await getProducts(productID, {})
    console.log(response.data.data[0])
    if (response.data) {
      setDetails(response.data.data[0])
    } else {
      if (response.error.status === 500) {
        toast.error(`Error 500: ${response.error.data.message}`)
      } else {
        toast.error(response.error.data.messages.error_messages.message)
      }
    }
  }
  useEffect(() => {
    fetchProduct()
  }, [])

  console.log("details", details)

  return (
    <div className="row register-container bg-dashboard justify-content-center">
      <NavbarTop />
      <div>
        {" "}
        <BackButton />
      </div>
      <Col xs={12} md={10} className="centered" style={{ overflowX: "hidden" }}>
        <Row className="view-product justify-content-center">
          {/* <Col md={12} className="mt-5">
          <Row className="view-product"> */}
          <Col md={6}>
            <img
              src={"/assets/diabetasol.png"}
              alt="product"
              className={"product-img"}
            />
          </Col>
          <Col md={6} className="text-left">
            <div className="forgot-label">{details.name}</div>
            {/* <div className="product-desc">
              <small>{details.remarks}</small>
            </div> */}
            <div className="mt-3">
              {" "}
              <small>{details.remarks}</small>
            </div>
            <div className="product-disc mt-3">
              <label>
                <strong>
                  ₱{parseFloat(details.discount).toFixed(2)} off /{" "}
                  {details.unit?.toLowerCase()}
                </strong>
              </label>
              <br />
              <small>with Discount Code</small>
            </div>
          </Col>
          {/* </Row>
        </Col> */}
        </Row>
      </Col>
    </div>
  )
}
