import React, { useEffect, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import { Row, Col, Form, InputGroup } from "react-bootstrap"
import InputError from "../../Components/InputError/InputError"
import "../Login/Login.css"
import "../../Components/Forms/Forms.css"
import { useNavigate } from "react-router-dom"
import BackButton from "../../Components/Back/BackButton"
import { loginUser } from "../../Helpers/API/authApi"
import ReactLoading from "react-loading"
import { validateLogin } from "../../Helpers/Validations/CreateAccountValidation/CreateAccountValidation"
import { getToken, refreshPage } from "../../Helpers/Utils/Common"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function Login({ navigation, details, setDetails }) {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    ecard_no: details.ecard_no,
    password: details.password,
  })
  const [ipAddress, setIpAddress] = useState("")
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIpAddress(data.ip))
      .catch((error) => console.error("Error fetching IP:", error))
  }, [])

  const [isError, setIsError] = useState({
    ecard_no: false,
    password: false,
  })

  function handleChange(e) {
    let newData = { ...data }
    const { name, value } = e.target
    newData[name] = value
    setData(newData)
  }

  async function handleSubmit() {
    if (validateLogin(data, setIsError)) {
      setLoading(true)
      const response = await loginUser(data.ecard_no, data.password, ipAddress)

      if (response.data) {
        let details = response.data

        toast.success(details.message)

        setTimeout(() => {
          localStorage.setItem("token", details.token)
          localStorage.setItem("token_expiry", details.token_expiry)
          localStorage.setItem("name", details.name)
          localStorage.setItem("role", details.role)
          localStorage.setItem("user_id", details.user_id)
          localStorage.setItem("user_name", details.user_name)
          navigate("/dashboard")
          refreshPage()
        }, 2000)
      } else {
        setLoading(false)
        if (response?.error?.status === 500) {
          toast.error(`Error 500: ${response.error.data.message}`)
        } else {
          toast.error(response.error.data.messages.error_messages.message)
        }
      }
    }
  }

  return (
    <div className="row login-container bg-login">
      <div className="mt-5">
        {" "}
        <BackButton
          onClick={() => {
            navigation.next()
            setDetails({ ...details, ecard_no: "" })
            setData({ ...data, ecard_no: "", password: "" })
          }}
        />
      </div>
      <div className="content-container">
        <Row>
          <Col sm={12} md={6} className="p-5 pt-0 text-left">
            <small className="text-left">WELCOME TO</small>
            <div className="text-left login-header-2  p-0 ">Patient Care</div>
          </Col>
          <Col sm={12} md={6} className="p-5 text-center">
            <Row className="login-div">
              <Col xs={12}>
                <div className="text-left">Login to your account</div>
                <Form.Group className="mt-3 text-left">
                  <Form.Label className="h6" htmlFor="name">
                    E-Card No.
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      name="ecard_no"
                      className="form-control ecard disabled"
                      onChange={handleChange}
                      value={data.ecard_no}
                      disabled={details.is_scanned}
                    />
                    <InputError
                      isValid={isError.ecard_no}
                      message={"This field is required"}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mt-3 text-left">
                  <Form.Label className="h6" htmlFor="name">
                    Password
                  </Form.Label>

                  <InputGroup className="mb-3">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      name="password"
                      className="form-control"
                      onChange={handleChange}
                    />
                    <InputError
                      isValid={isError.password}
                      message={"This field is required"}
                    />
                    <InputGroup.Text id="basic-addon1">
                      {" "}
                      <FontAwesomeIcon
                        icon={showPassword ? "fa-eye" : "fa-eye-slash"}
                        alt={"open"}
                        className={"max-menu"}
                        aria-hidden="true"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    </InputGroup.Text>
                  </InputGroup>
                  <small
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/forget-password")
                    }}
                  >
                    <u>Forgot Password?</u>
                  </small>
                </Form.Group>
              </Col>
              <Col md={12}>
                {loading ? (
                  <button
                    // type="submit"
                    className={"login-btn"}
                    onClick={handleSubmit}
                    style={{ textAlign: "-webkit-center" }}
                  >
                    <ReactLoading
                      type={"spin"}
                      height={20}
                      width={25}
                      color="#fff"
                    />
                  </button>
                ) : (
                  <button
                    // type="submit"
                    className={"login-btn"}
                    onClick={handleSubmit}
                  >
                    LOGIN
                  </button>
                )}
                <small>
                  Don't have an account?{" "}
                  <strong onClick={() => navigate("/register")}>
                    Create Account
                  </strong>
                </small>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <ToastContainer theme="colored" />
    </div>
  )
}
