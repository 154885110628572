import React from "react"
import { Form, Row, Col } from "react-bootstrap"

import "./Forms.css"
import InputError from "./../InputError/InputError"

function MYTTextField({
  required,
  type,
  formLabel,
  size,
  placeholder,
  name,
  value,
  onChange,
  isError,
  errorMessage = "",
  inputType = "text",
}) {
  return (
    <Form>
      {type === "inline" ? (
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Row>
            <Col sm={3}>
              <Form.Label className="p-1 form-label align-left">
                {formLabel}
                {required && <span className="badge">*</span>}
              </Form.Label>
            </Col>
            <Col sm={6}>
              <Form.Control
                type={inputType}
                placeholder={placeholder}
                size={size}
                name={name}
                value={value}
                onChange={onChange}
              />
              <InputError
                isValid={isError}
                message={
                  errorMessage !== "" ? errorMessage : "This field is required"
                }
              />
            </Col>
          </Row>
        </Form.Group>
      ) : type === "nolabel" ? (
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Control
            type={inputType}
            placeholder={placeholder}
            size={size}
            name={name}
            value={value}
            onChange={onChange}
          />
          <InputError
            isValid={isError}
            message={
              errorMessage !== "" ? errorMessage : "This field is required"
            }
          />
        </Form.Group>
      ) : (
        <>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="align-left ml-2 form-label">
              {formLabel}
              {required && <span className="badge">*</span>}
            </Form.Label>
            <Form.Control
              type={inputType}
              placeholder={placeholder}
              size={size}
              name={name}
              value={value}
              onChange={onChange}
            />
            <InputError
              isValid={isError}
              message={
                errorMessage !== "" ? errorMessage : "This field is required"
              }
            />
          </Form.Group>
        </>
      )}
    </Form>
  )
}

export default MYTTextField
