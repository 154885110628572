import React, { useState } from "react"
import { Row, Col, Table, Button } from "react-bootstrap"

import "./VerifyPromoCode.css"

import back from "../../Assets/Images/back-arrow.png"
import { useNavigate } from "react-router-dom"
import NavbarTop from "../../Pages/NavbarTop/NavbarTop"
import ModalPopUp from "../Modals/Modals"
import MYTTextField from "../Forms/MYTTextField"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function VerifyOR() {
  const navigate = useNavigate()
  const [showModalPayment, setShowModalPayment] = useState(false)
  return (
    <div className="page dashboard">
      <NavbarTop />
      <div className={` container inactive"}`}>
        <Row className="mt-4 justify-content-center">
          <Col xs="12">
            <h2 className="label-text">VERIFY</h2>
          </Col>
        </Row>
        <Row className="mt-3 text-left justify-content-center">
          <Col xs={4}>
            <MYTTextField
              type={`block`}
              formLabel={`OR NO`}
              size={`sm`}
              placeholder={``}
              // onChange={handleAddChange}
            />
          </Col>
          <Col xs={4}>
            <MYTTextField
              type={`block`}
              formLabel={`UPLOAD OR`}
              size={`sm`}
              placeholder={``}
              // onChange={handleAddChange}
            />
          </Col>
          <Col xs={4}>
            <MYTTextField
              type={`block`}
              formLabel={`UPLOAD DOCTOR'S PRESCRIPTION`}
              size={`sm`}
              placeholder={``}
              // onChange={handleAddChange}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xs={12}>
            <h4 className="label-text">DETAILS</h4>
          </Col>
          <Col xs={12}>
            <Table>
              <thead>
                <tr style={{ color: "var(--primary-color)" }}>
                  <th>ITEM PURCHASED</th>
                  <th>CASHBACK</th>
                  <th>QUANTITY PURCHASED</th>
                  <th>TOTAL CASHBACK</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {" "}
                    <MYTTextField
                      type={`no-label`}
                      //   formLabel={`UPLOAD DOCTOR'S PRESCRIPTION`}
                      size={`sm`}
                      placeholder={``}
                      // onChange={handleAddChange}
                    />
                  </td>
                  <td>P 0.00</td>
                  <td>
                    {" "}
                    <MYTTextField
                      type={`no-label`}
                      //   formLabel={`UPLOAD DOCTOR'S PRESCRIPTION`}
                      size={`sm`}
                      placeholder={``}
                      // onChange={handleAddChange}
                    />
                  </td>
                  <td>P 0.00</td>
                  <td>
                    {" "}
                    <FontAwesomeIcon
                      icon={"fa-square-plus"}
                      alt={"plus"}
                      className={"csv-icon"}
                      aria-hidden="true"
                      color="#5E5ADB"
                    />{" "}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="justify-content-center mt-3">
          <Col
            xs={4}
            className="align-center"
            style={{ color: "var(--primary-color)" }}
          >
            <h5>
              <strong>TOTAL CASHBACK: P 0.00</strong>
            </h5>
          </Col>
        </Row>
        <Row className="mt-5 justify-content-center">
          <Col xs={4}>
            <Button size="md" className="claim-btn" style={{ width: "100%" }}>
              VERIFY
            </Button>
          </Col>
        </Row>
      </div>
      <ModalPopUp
        type="payment"
        show={showModalPayment}
        handleClose={() => setShowModalPayment(false)}
      />
    </div>
  )
}
