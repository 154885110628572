import React, { useState } from "react"
import { Row, Col, Table } from "react-bootstrap"

import "./VerifyPromoCode.css"

import back from "../../Assets/Images/back-arrow.png"
import { useNavigate } from "react-router-dom"
import NavbarTop from "../../Pages/NavbarTop/NavbarTop"
import ModalPopUp from "../Modals/Modals"

export default function VerifyPromoCode() {
  const navigate = useNavigate()
  const [showModalPayment, setShowModalPayment] = useState(false)
  return (
    <div className="page dashboard">
      <NavbarTop />
      <div className={` container inactive"}`}>
        <Row className="mt-4 justify-content-between">
          <Col xs="2">
            <img src={back} alt="back" onClick={() => navigate(-1)} />
          </Col>
          <Col xs="10">
            <h2 className="label-text">TRANSACTION DETAILS</h2>
          </Col>
        </Row>
        <Row className="mt-3 text-left justify-content-center">
          <Col xs={12} className="p-2">
            <Row>
              <Col className="bg-purple">OR NO</Col>
              <Col className="text-center">09023</Col>
              <Col className="bg-purple">UPLOAD OR</Col>
              <Col className="text-center">09023</Col>
            </Row>
          </Col>
          <Col xs={12} className="p-2">
            <Row>
              <Col className="bg-purple">STATUS</Col>
              <Col className="text-center">09023</Col>
              <Col className="bg-purple">REFERENCE NUMBER</Col>
              <Col className="text-center">09023</Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xs={12}>
            <h4 className="label-text">DETAILS</h4>
          </Col>
          <Col xs={12}>
            <Table>
              <thead>
                <tr style={{ color: "var(--primary-color)" }}>
                  <th>ITEM PURCHASED</th>
                  <th>CASHBACK</th>
                  <th>QUANTITY PURCHASED</th>
                  <th>TOTAL CASHBACK</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>SAMPLE</td>
                  <td>P 30.00</td>
                  <td>5</td>
                  <td>P 150.00</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="justify-content-center mt-3">
          <Col
            xs={4}
            className="align-center"
            style={{ color: "var(--primary-color)" }}
          >
            <h5>
              <strong>TOTAL CASHBACK: P 150.00</strong>
            </h5>
          </Col>
        </Row>
      </div>
      <ModalPopUp
        type="payment"
        show={showModalPayment}
        handleClose={() => setShowModalPayment(false)}
      />
    </div>
  )
}
