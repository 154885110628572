import React, { useState } from "react"
import { toast } from "react-toastify"

import { Row, Col, Form, InputGroup } from "react-bootstrap"
import { createAccount } from "../ ../../Helpers/API/CreateAccountApi/CreateAccountApi"
import {
  validateCheckAccount,
  validateCreateAccount,
} from "../Helpers/Validations/CreateAccountValidation/CreateAccountValidation"

import { useNavigate } from "react-router-dom"
import InputError from "../Components/InputError/InputError"
import { Modal } from "antd"
import QrReader from "react-qr-scanner"
import { checkUser } from "../Helpers/API/passwordAPI"
import ReactLoading from "react-loading"

export default function ForgotPassword() {
  const navigate = useNavigate()
  const [disableClick, setDisableClick] = useState(false)
  const [isCreated, setIsCreated] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [showScanner, setShowScanner] = useState(false)
  const [scannerDetails, setScannerDetails] = useState({
    delay: 1000,
    result: "",
  })
  const [details, setDetails] = useState({
    ecard_no: "",
    mobile_no: "",
    is_scanned: false,
  })
  const [loading, setLoading] = useState(false)
  const [isError, setIsError] = useState({
    ecard_no: false,
    mobile_no: false,
    mobile_no_length: false,
  })

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }

  function handleChange(e) {
    let newData = { ...details }
    const { name, value } = e.target
    newData[name] = value
    setDetails(newData)
  }

  async function handleCheckAccount() {
    if (validateCheckAccount(details, setIsError) && !disableClick) {
      setLoading(true)
      setDisableClick(true)
      const response = await checkUser(details)

      if (response.data) {
        // setTimeout()
        localStorage.setItem("user_id", response.data.user_id)
        navigate("/reset-password")
      } else {
        setLoading(false)
        setDisableClick(false)
        if (response.error.status === 500) {
          toast.error(`Error 500: ${response.error.data.message}`)
        } else {
          toast.error(response.error.data.messages.error_messages.message)
        }
      }
    }
  }

  const handleError = (err) => {
    console.log("eroor", err)
  }
  const handleScan = (data) => {
    if (data !== null) {
      setScannerDetails({ ...scannerDetails, result: data.text })
      setDetails({ ...details, ecard_no: data.text, is_scanned: true })
      setShowScanner(false)
    }
  }

  return (
    <div className="row login-container bg-dashboard">
      <div className="content-container">
        <Row className=" mt-5 justify-content-center">
          <Col xs={12} md={6} className="mt-5 p-5">
            <Row className="forgot-password">
              <Col md={12}>
                <div className="forgot-label">Forgot Password</div>
                <hr />{" "}
                <small>
                  Please enter your mobile number to set your new password.
                </small>
                <Form.Group className="mt-3 text-left mt-5">
                  {/* <Form.Label className="h6" htmlFor="name">
                  Mobile Number
                </Form.Label> */}
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">+63</InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="mobile_no"
                      className="form-control"
                      onChange={handleChange}
                      placeholder="Mobile Number"
                      maxLength={10}
                      onKeyPress={(e) => {
                        // Prevent entering "-" character
                        // if (/^\d+$/.test(e.key) === false) {
                        //   e.preventDefault()
                        // }

                        if (
                          /^\s*$/.test(e.key) ||
                          /^\d+$/.test(e.key) === false
                        ) {
                          e.preventDefault()
                        }
                      }}
                    />
                    <InputError
                      isValid={isError.mobile_no}
                      message={"This field is required"}
                    />
                    <InputError
                      isValid={isError.mobile_no_length}
                      message={"Mobile number should contain 10 digits."}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={8}>
                <Form.Group className=" text-left">
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      name="ecard_no"
                      className="form-control ecard disabled"
                      onChange={handleChange}
                      placeholder="Ecard No"
                      value={details.ecard_no}
                      disabled={details.is_scanned}
                    />
                    <InputError
                      isValid={isError.ecard_no}
                      message={"This field is required"}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={4}>
                <button
                  type="submit"
                  className={"login-btn scan pt-0 mt-2"}
                  onClick={() => setShowScanner(true)}
                >
                  Scan
                </button>
              </Col>
              <Col md={12}>
                {loading ? (
                  <button
                    type="submit"
                    className={"login-btn small"}
                    style={{ textAlign: "-webkit-center" }}
                  >
                    <ReactLoading
                      type={"spin"}
                      height={20}
                      width={25}
                      color="#fff"
                    />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className={"login-btn small"}
                    onClick={handleCheckAccount}
                  >
                    Proceed
                  </button>
                )}

                <br />
                <small>
                  Take me back to{" "}
                  <u
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/login")}
                  >
                    Login
                  </u>{" "}
                  screen.
                </small>
              </Col>
            </Row>
          </Col>
        </Row>
        <Modal
          title="Scan Ecard"
          centered
          open={showScanner}
          onOk={() => {
            setDetails({ ...details, is_scanned: false })
            setShowScanner(false)
          }}
          onCancel={() => {
            setDetails({ ...details, is_scanned: false })
            setShowScanner(false)
          }}
        >
          <QrReader
            delay={scannerDetails.delay}
            style={{
              height: 240,
              width: 500,
            }}
            onError={handleError}
            onScan={handleScan}
          />
        </Modal>
      </div>
    </div>
  )
}
