import { getAPICall, postAPICall } from "../axiosMethodCalls"

export const createAccount = async (data) => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "users/create_patient",
      { ...data }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const checkQR = async (data) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "users/check",
      { ...data }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}
