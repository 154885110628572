import React, { useState } from "react"
import { useStep } from "react-hooks-helper"
import LoginQR from "./LoginQR"
import Login from "./Login"

const serviceData = {}
const mdData = {}

export default function SwitchLogin() {
  const steps = [{ id: "qr" }, { id: "login" }]
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  })

  const [details, setDetails] = useState({
    ecard_no: "",
    password: "",
    is_scanned: false,
  })

  const props = { navigation, details, setDetails }

  switch (step.id) {
    case "qr":
      return <LoginQR {...props} />
    case "login":
      return <Login {...props} />
  }

  return <div></div>
}
