import React, { useState, useEffect } from "react"
import { ToastContainer, toast } from "react-toastify"
import ReactLoading from "react-loading"
import { Row, Col, Container, Form, InputGroup, Badge } from "react-bootstrap"
import { createAccount } from "../../Helpers/API/CreateAccountApi/CreateAccountApi"
import { validateCreateAccount } from "../../Helpers/Validations/CreateAccountValidation/CreateAccountValidation"
import InputError from "../../Components/InputError/InputError"
import patientLogin from "../../Assets/Login/patientLogin.png"
import PatientCareProgram from "../../Components/PatientCareProgram/PatientCareProgram"
import CelebrationIcon from "@mui/icons-material/Celebration"
import "../Login/Login.css"
import "../../Components/Forms/Forms.css"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import BackButton from "../../Components/Back/BackButton"
import { refreshPage } from "../../Helpers/Utils/Common"
import { faBedPulse } from "@fortawesome/free-solid-svg-icons"

export default function RegisterCont({ navigation, details, setDetails }) {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)

  const [isError, setIsError] = useState({
    password: false,
    confirm_password: false,
    password_length: false,
  })

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }

  function handleChange(e) {
    let newData = { ...details }
    const { name, value } = e.target
    newData[name] = value
    setDetails(newData)
  }

  async function handleRegister() {
    if (validateCreateAccount(details, setIsError)) {
      setLoading(true)
      const response = await createAccount(details)
      if (response.data) {
        navigate("/register/success")
      } else {
        setLoading(false)

        if (response.error.status === 500) {
          toast.error(`Error 500: ${response.error.data.message}`)
        } else {
          toast.error(response.error.data.messages.error_messages.message)
          setTimeout(() => {
            refreshPage()
          }, 3000)
        }
      }
    }
  }

  return (
    <div className="row register-container bg-register">
      <ToastContainer theme="colored" />
      <div className="mt-5">
        {" "}
        <BackButton onClick={() => navigation.previous()} />
      </div>
      <div className="content-container">
        <Row className="justify-content-center">
          <Col xs={12} className="mb-5">
            {/* <div className="text-left">
            <FontAwesomeIcon
              icon={"fa-arrow-left"}
              alt={"open"}
              className={"max-menu"}
              color="var(--secondary-color)"
              aria-hidden="true"
            />{" "}
            Back
          </div> */}
          </Col>
          <Col md={6} sm={12} className="p-5 pb-0 pt-0 mt-5">
            <small className="text-left">WELCOME TO</small>
            <div className="text-left login-header-2  p-0 ">Patient Care</div>
          </Col>
          <Col md={6} sm={12} className="p-5 text-center centered">
            <Row className="login-div">
              <Col xs={12}>
                <div className="text-left">Set your password.</div>
                <Form.Group className="mt-3 text-left">
                  <Form.Label className="h6" htmlFor="name">
                    E-Card No.
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      name="ecard_no"
                      value={details.ecard_no}
                      disabled
                      className="form-control"
                      onChange={handleChange}
                    />
                    <InputError
                      isValid={isError.agent_id}
                      message={"This field is required"}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group className="mt-3 text-left">
                  <Form.Label className="h6" htmlFor="name">
                    Name
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      name="name"
                      value={details.name}
                      disabled
                      className="form-control"
                      onChange={handleChange}
                    />
                    <InputError
                      isValid={isError.agent_id}
                      message={"This field is required"}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group className="mt-3 text-left">
                  <Form.Label className="h6" htmlFor="name">
                    Contact Number
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>+63</InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="contact_number"
                      value={details.contact_no}
                      disabled
                      className="form-control"
                      onChange={handleChange}
                      max="11"
                      onKeyPress={(e) => {
                        // Prevent entering "-" character
                        if (/^\d+$/.test(e.key) === false) {
                          e.preventDefault()
                        }
                      }}
                    />
                    <InputError
                      isValid={isError.agent_id}
                      message={"This field is required"}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group className="mt-3 text-left">
                  <Form.Label className="h6" htmlFor="name">
                    Email Address (Optional){" "}
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      name="email"
                      className="form-control"
                      onChange={handleChange}
                    />
                    <InputError
                      isValid={isError.email}
                      message={"This field is required"}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group className="mt-3 text-left">
                  <Form.Label className="h6" htmlFor="name">
                    Password
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      name="password"
                      className="form-control"
                      onChange={handleChange}
                    />
                    <InputError
                      isValid={isError.password}
                      message={"This field is required"}
                    />
                    <InputError
                      isValid={isError.confirm_password}
                      message={"Passwords do not match"}
                    />
                    <InputError
                      isValid={isError.password_length}
                      message={
                        "Passwords should at least contain 6 characters."
                      }
                    />

                    <InputGroup.Text id="basic-addon1">
                      {" "}
                      <FontAwesomeIcon
                        icon={showPassword ? "fa-eye" : "fa-eye-slash"}
                        alt={"open"}
                        className={"max-menu"}
                        aria-hidden="true"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="mt-3 text-left">
                  <Form.Label className="h6" htmlFor="name">
                    Re-enter Password
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type={showNewPassword ? "text" : "password"}
                      name="confirm_password"
                      className="form-control"
                      onChange={handleChange}
                    />
                    <InputError
                      isValid={isError.password}
                      message={"This field is required"}
                    />
                    <InputError
                      isValid={isError.confirm_password}
                      message={"Passwords do not match"}
                    />
                    <InputError
                      isValid={isError.password_length}
                      message={
                        "Passwords should at least contain 6 characters."
                      }
                    />
                    <InputGroup.Text id="basic-addon1">
                      {" "}
                      <FontAwesomeIcon
                        icon={showNewPassword ? "fa-eye" : "fa-eye-slash"}
                        alt={"open"}
                        className={"max-menu"}
                        aria-hidden="true"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                      />
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={12}>
                {loading ? (
                  <button
                    type="submit"
                    className={"login-btn"}
                    // onClick={handleRegister}
                    style={{ textAlign: "-webkit-center" }}
                  >
                    <ReactLoading
                      type={"spin"}
                      height={20}
                      width={25}
                      color="#fff"
                    />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className={"login-btn"}
                    onClick={handleRegister}
                  >
                    REGISTER
                  </button>
                )}
                <small>
                  Already have an account?{" "}
                  <strong
                    onClick={() => {
                      navigate("/login")
                    }}
                  >
                    Login
                  </strong>
                </small>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  )
}
