import React, { useState } from "react"
import { useStep } from "react-hooks-helper"
import Register from "./Register"
import RegisterCont from "./RegisterCont"
import RegisterQR from "./RegisterQR"

const serviceData = {}
const mdData = {}

export default function SwitchRegister() {
  const steps = [{ id: "first" }, { id: "second" }, { id: "third" }]
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  })

  const [details, setDetails] = useState({
    ecard_no: "",
    name: "",
    contact_no: "",
    email: "",
    password: "",
    confirm_password: "",
    is_agree: false,
    is_scanned: false,
  })

  const props = { navigation, details, setDetails }

  switch (step.id) {
    case "first":
      return <RegisterQR {...props} />
    case "second":
      return <Register {...props} />
    case "third":
      return <RegisterCont {...props} />
  }

  return <div></div>
}
