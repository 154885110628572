import React, { Fragment, useEffect, useState } from "react"
import { Row, Col } from "react-bootstrap"

//CSS
import "../Forms/Form.css"
import "./Dashboard.css"
//Images
import NavbarTop from "../NavbarTop/NavbarTop"
import { formatDate, getName, getUser } from "../../Helpers/Utils/Common"
import arrow from "../../Assets/Images/arrow.png"
import { useNavigate } from "react-router-dom"
import { getPromos } from "../../Helpers/API/discountAPI"
import { ToastContainer, toast } from "react-toastify"

const dummy = [
  {
    type: "bundle",
    title: "Cashback Per Bundle",
    product: "Biogesic",
    description: "Get P10.00 for every 3 pieces",
    start_date: new Date("11/20/2023"),
    end_date: new Date("11/30/2023"),
  },
  {
    type: "piece",
    title: "Cashback Per Bundle",
    product: "Biogesic",
    description: "Get P10.00 for every 3 pieces",
    start_date: new Date("11/20/2023"),
    end_date: new Date("11/30/2023"),
  },
  {
    type: "amount",
    title: "Cashback Per Bundle",
    product: "Biogesic",
    description: "Get P10.00 for every 3 pieces",
    start_date: new Date("11/20/2023"),
    end_date: new Date("11/30/2023"),
  },
  {
    type: "bundle",
    title: "Cashback Per Bundle",
    product: "Biogesic",
    description: "Get P10.00 for every 3 pieces",
    start_date: new Date("11/20/2023"),
    end_date: new Date("11/30/2023"),
  },
  {
    type: "piece",
    title: "Cashback Per Bundle",
    product: "Biogesic",
    description: "Get P10.00 for every 3 pieces",
    start_date: new Date("11/20/2023"),
    end_date: new Date("11/30/2023"),
  },
]

function Dashboard() {
  const navigate = useNavigate()
  const [promos, setPromos] = useState([])
  const [entrasolPromos, setEntrasolPromos] = useState([])
  const [diabetasolPromos, setDiabetasolPromos] = useState([])
  const [filter, setFilter] = useState("all")

  async function fetchPromos() {
    const response = await getPromos()
    // if (response?.data) {
    //   setPromos(response.data.data)
    //   setEntrasolPromos(
    //     response.data.data.filter((data) => data.type === "entrasol")
    //   )
    //   setDiabetasolPromos(
    //     response.data.data.filter((data) => data.type === "diabetasol")
    //   )
    // } else {
    //   toast.error(response.error.data.messages.error)
    // }
  }

  function handleFilter(value) {
    setFilter(value)
  }

  useEffect(() => {
    fetchPromos()
  }, [])
  return (
    <div className="page bg-dashboard">
      <ToastContainer theme="colored" />
      <NavbarTop />
      <div className={` container inactive"}`}>
        <Row className="mt-4 justify-content-between">
          <Col xs="12">
            <h1 className="page-title left">
              WELCOME, {getName().toString().replaceAll(`"`, ``)}!
            </h1>
          </Col>
          <Col xs="12" md="4" className="mt-2">
            <button className="verify-btn" onClick={() => handleFilter("all")}>
              ALL
            </button>
          </Col>
          <Col
            xs="12"
            md="4"
            className="mt-2"
            onClick={() => handleFilter("entrasol")}
          >
            <button className="verify-btn">ENTRASOL</button>
          </Col>
          <Col
            xs="12"
            md="4"
            className="mt-2"
            onClick={() => handleFilter("diabetasol")}
          >
            <button className="verify-btn">DIABETASOL</button>
          </Col>
        </Row>
        <hr className="hr-line" />
        {filter === "all" && (
          <Fragment>
            <Row>
              <Col className="align-left">
                <div className="discount-label">RECENT DISCOUNTS</div>
              </Col>
            </Row>
            <Row>
              {dummy.map((data) => {
                return (
                  <Col xs={12} md={4}>
                    <Row className="justify-content-center p-2 m-2 promo-card">
                      <Col xs={1}>
                        <div className={`vl ${data.type}`}></div>
                      </Col>
                      <Col xs={11} className="text-left">
                        <h5>{data.title}</h5>
                        <h6>
                          <strong>{data.product.toUpperCase()}</strong>
                        </h6>
                        <p>{data.description}</p>
                      </Col>

                      <Col xs={5} className="mt-2 mb-2">
                        <strong className="bg-grey">Start Date</strong> <br />
                        {formatDate(data.start_date)}
                      </Col>
                      <Col xs={1} className="mt-3 text-center">
                        <img src={arrow} alt={"arrow"} />
                      </Col>
                      <Col xs={5} className="mt-2 mb-2">
                        <strong className="bg-grey">End Date</strong> <br />
                        {formatDate(data.end_date)}
                      </Col>
                    </Row>
                  </Col>
                )
              })}
            </Row>
            <Row className="mt-5">
              <Col className="align-left">
                <div className="discount-label">ENTRASOL DISCOUNTS</div>
              </Col>
            </Row>
            <Row>
              {dummy.map((data) => {
                return (
                  <Col xs={12} md={4}>
                    <Row className="justify-content-center p-2 m-2 promo-card">
                      <Col xs={1}>
                        <div className={`vl ${data.type}`}></div>
                      </Col>
                      <Col xs={11} className="text-left">
                        <h5>{data.title}</h5>
                        <h6>
                          <strong>{data.product.toUpperCase()}</strong>
                        </h6>
                        <p>{data.description}</p>
                      </Col>

                      <Col xs={5} className="mt-2 mb-2">
                        <strong className="bg-grey">Start Date</strong> <br />
                        {formatDate(data.start_date)}
                      </Col>
                      <Col xs={1} className="mt-3 text-center">
                        <img src={arrow} alt={"arrow"} />
                      </Col>
                      <Col xs={5} className="mt-2 mb-2">
                        <strong className="bg-grey">End Date</strong> <br />
                        {formatDate(data.end_date)}
                      </Col>
                    </Row>
                  </Col>
                )
              })}
            </Row>
            <Row className="mt-5">
              <Col className="align-left">
                <div className="discount-label">DIABETASOL DISCOUNTS</div>
              </Col>
            </Row>
            <Row className="mb-5">
              {dummy.map((data) => {
                return (
                  <Col xs={12} md={4}>
                    <Row className="justify-content-center p-2 m-2 promo-card">
                      <Col xs={1}>
                        <div className={`vl ${data.type}`}></div>
                      </Col>
                      <Col xs={11} className="text-left">
                        <h5>{data.title}</h5>
                        <h6>
                          <strong>{data.product.toUpperCase()}</strong>
                        </h6>
                        <p>{data.description}</p>
                      </Col>

                      <Col xs={5} className="mt-2 mb-2">
                        <strong className="bg-grey">Start Date</strong> <br />
                        {formatDate(data.start_date)}
                      </Col>
                      <Col xs={1} className="mt-3 text-center">
                        <img src={arrow} alt={"arrow"} />
                      </Col>
                      <Col xs={5} className="mt-2 mb-2">
                        <strong className="bg-grey">End Date</strong> <br />
                        {formatDate(data.end_date)}
                      </Col>
                    </Row>
                  </Col>
                )
              })}
            </Row>
          </Fragment>
        )}
        {filter === "entrasol" && (
          <Fragment>
            <Row className="mt-5">
              <Col className="align-left">
                <div className="discount-label">ENTRASOL DISCOUNTS</div>
              </Col>
            </Row>
            <Row>
              {dummy.map((data) => {
                return (
                  <Col xs={12} md={4}>
                    <Row className="justify-content-center p-2 m-2 promo-card">
                      <Col xs={1}>
                        <div className={`vl ${data.type}`}></div>
                      </Col>
                      <Col xs={11} className="text-left">
                        <h5>{data.title}</h5>
                        <h6>
                          <strong>{data.product.toUpperCase()}</strong>
                        </h6>
                        <p>{data.description}</p>
                      </Col>

                      <Col xs={5} className="mt-2 mb-2">
                        <strong className="bg-grey">Start Date</strong> <br />
                        {formatDate(data.start_date)}
                      </Col>
                      <Col xs={1} className="mt-3 text-center">
                        <img src={arrow} alt={"arrow"} />
                      </Col>
                      <Col xs={5} className="mt-2 mb-2">
                        <strong className="bg-grey">End Date</strong> <br />
                        {formatDate(data.end_date)}
                      </Col>
                    </Row>
                  </Col>
                )
              })}
            </Row>
          </Fragment>
        )}

        {filter === "diabetasol" && (
          <Fragment>
            <Row className="mt-5">
              <Col className="align-left">
                <div className="discount-label">DIABETASOL DISCOUNTS</div>
              </Col>
            </Row>
            <Row className="mb-5">
              {dummy.map((data) => {
                return (
                  <Col xs={12} md={4}>
                    <Row className="justify-content-center p-2 m-2 promo-card">
                      <Col xs={1}>
                        <div className={`vl ${data.type}`}></div>
                      </Col>
                      <Col xs={11} className="text-left">
                        <h5>{data.title}</h5>
                        <h6>
                          <strong>{data.product.toUpperCase()}</strong>
                        </h6>
                        <p>{data.description}</p>
                      </Col>

                      <Col xs={5} className="mt-2 mb-2">
                        <strong className="bg-grey">Start Date</strong> <br />
                        {formatDate(data.start_date)}
                      </Col>
                      <Col xs={1} className="mt-3 text-center">
                        <img src={arrow} alt={"arrow"} />
                      </Col>
                      <Col xs={5} className="mt-2 mb-2">
                        <strong className="bg-grey">End Date</strong> <br />
                        {formatDate(data.end_date)}
                      </Col>
                    </Row>
                  </Col>
                )
              })}
            </Row>
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default Dashboard
