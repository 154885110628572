import React, { useEffect, useState } from "react"
import { Row, Col, Form, InputGroup, Pagination } from "react-bootstrap"

//Images
import NavbarTop from "../NavbarTop/NavbarTop"
import { formatDate } from "../../Helpers/Utils/Common"
import arrow from "../../Assets/Images/arrow.png"
import InputError from "../../Components/InputError/InputError"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate, useParams } from "react-router-dom"
import { getProducts } from "../../Helpers/API/productsAPI"
import { ToastContainer, toast } from "react-toastify"
import NoDataPrompt from "../../Components/Table/NoDataPrompt/NoDataPrompt"

const dummy = [
  {
    type: "bundle",
    title: "Cashback Per Bundle",
    product: "Biogesic",
    description: "Get P10.00 for every 3 pieces",
    start_date: new Date("11/20/2023"),
    end_date: new Date("11/30/2023"),
  },
  {
    type: "piece",
    title: "Cashback Per Bundle",
    product: "Biogesic",
    description: "Get P10.00 for every 3 pieces",
    start_date: new Date("11/20/2023"),
    end_date: new Date("11/30/2023"),
  },
  {
    type: "amount",
    title: "Cashback Per Bundle",
    product: "Biogesic",
    description: "Get P10.00 for every 3 pieces",
    start_date: new Date("11/20/2023"),
    end_date: new Date("11/30/2023"),
  },
  {
    type: "bundle",
    title: "Cashback Per Bundle",
    product: "Biogesic",
    description: "Get P10.00 for every 3 pieces",
    start_date: new Date("11/20/2023"),
    end_date: new Date("11/30/2023"),
  },
  {
    type: "piece",
    title: "Cashback Per Bundle",
    product: "Biogesic",
    description: "Get P10.00 for every 3 pieces",
    start_date: new Date("11/20/2023"),
    end_date: new Date("11/30/2023"),
  },
]

const categories = ["All", "Diabetasol", "Entrasol"]
export default function Products() {
  const [products, setProducts] = useState([])
  const [entrasolProducts, setEntrasolProducts] = useState([])
  const [diabetasolProducts, setDiabetasolProducts] = useState([])
  const navigate = useNavigate()
  const [filters, setFilters] = useState({
    category: "All",
  })
  const [pagination, setPagination] = useState({
    pages: [],
  })

  function computePagination(response) {
    let pages = 10,
      page_copy = []
    // let pages = Math.ceil(response.data.size / 10)
    if (pages === 0) {
      page_copy = []
    } else {
      for (var i = 1; i <= pages; i++) {
        page_copy.push(i)
      }
    }
    setPagination({ ...pagination, pages: page_copy })
  }

  async function handleFilterProduct() {
    const response = await getProducts("", filters)
    // computePagination(1)
    if (response?.data) {
      setProducts(response?.data.data)
    }
    //  else {
    //   if (response.error.status === 500) {
    //     toast.error(`Error 500: ${response.error.data.message}`)
    //   } else {
    //     toast.error(response.error.data.messages.error_messages.message)
    //   }
    // }
  }

  async function fetchProducts() {
    const response = await getProducts("", filters)

    // computePagination(1)
    if (response?.data) {
      setProducts(response?.data.data)
    }
    // else {
    //   if (response.error.status === 500) {
    //     toast.error(`Error 500: ${response.error.data.message}`)
    //   } else {
    //     toast.error(response.error.data.messages.error_messages.message)
    //   }
    // }
  }

  useEffect(() => {
    fetchProducts()
  }, [])
  useEffect(() => {
    handleFilterProduct()
  }, [filters])
  return (
    <div className="page bg-dashboard">
      <ToastContainer theme="colored" />
      <NavbarTop />
      <div className={` container inactive"}`} style={{ overflowX: "hidden" }}>
        <Row className="mt-4 justify-content-between">
          <Col xs="12" md="6" className="text-left">
            <div className="discount-label">Discounts</div>
          </Col>

          <Col xs="12" md="3" className="text-right">
            <div className="discount-label">
              {/* <InputGroup className="mb-3">
                <Form.Control
                  aria-label="Amount (to the nearest dollar)"
                  className="form-control"
                  placeholder="Search Product"
                  onChange={(e) => {
                    setFilters({ ...filters, name: e.target.value })
                  }}
                />
                <InputGroup.Text onClick={handleFilterProduct}>
                  <FontAwesomeIcon
                    icon={"fa-magnifying-glass"}
                    alt={"close"}
                    className={"csv-icon"}
                    aria-hidden="true"
                  />
                </InputGroup.Text>
              </InputGroup> */}
            </div>
          </Col>
        </Row>
        <hr className="hr-line" />
        <Row>
          <Col xs={12} md={3} className="text-left">
            <div className="categories">
              <strong>Categories</strong>
            </div>
            <hr />
            {categories.map((data) => {
              return (
                <div
                  className={`mt-2 prod-cat ${
                    filters.category?.toLowerCase() === data.toLowerCase()
                      ? "chosen"
                      : ""
                  }`}
                  onClick={() => setFilters({ ...filters, category: data })}
                  style={{ cursor: "pointer" }}
                >
                  <small>{data}</small>
                </div>
              )
            })}
          </Col>
          <Col xs={12} md={9}>
            <Row>
              <Col md={12} className="text-right">
                <Row className="justify-content-end">
                  <Col md={3}>
                    {/* <Pagination size="sm">
                      <Pagination.First />
                      <Pagination.Prev />
                      {pagination.pages.map((data) => {
                        return (
                          <Pagination.Item
                            onClick={() =>
                              setFilters({ ...filters, page: data })
                            }
                          >
                            {data}
                          </Pagination.Item>
                        )
                      })}

                      <Pagination.Next />
                      <Pagination.Last />
                    </Pagination> */}
                  </Col>
                </Row>
              </Col>
              <Col md={12} style={{ cursor: "pointer" }}>
                <Row>
                  {products.length < 1 && <NoDataPrompt />}
                  {products.map((data) => {
                    return (
                      <Col
                        xs={12}
                        md={3}
                        onClick={() =>
                          navigate("/view-product/" + data.promo_id + "")
                        }
                      >
                        <Row className="justify-content-center promo-card no-mp mb-3">
                          <Col xs={12} className="text-left no-mp pt-3">
                            <div className="custom-badge no-m">
                              P{parseFloat(data.discount).toFixed(2)} off/
                              {data.unit?.toLowerCase()}
                            </div>
                          </Col>
                          <Col xs={12}>
                            <img
                              src={"/assets/diabetasol.png"}
                              alt="product"
                              weight={100}
                              height={100}
                            />{" "}
                          </Col>
                          <Col xs={11} className="text-left mt-1">
                            <div className="product-title">{data.name}</div>

                            <p className="product-mini-desc product-remarks">
                              {data.remarks}
                            </p>
                          </Col>

                          <Col xs={12} className=" mb-2">
                            <button className="product-avail-btn">
                              View Discount
                            </button>
                          </Col>
                        </Row>
                      </Col>
                    )
                  })}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  )
}
