import React, { useState, useEffect } from "react"
import { ToastContainer, toast } from "react-toastify"
import ReactLoading from "react-loading"
import { Row, Col, Container, Form, InputGroup, Badge } from "react-bootstrap"
import { createAccount } from "../../Helpers/API/CreateAccountApi/CreateAccountApi"
import { validateCreateAccount } from "../../Helpers/Validations/CreateAccountValidation/CreateAccountValidation"
import InputError from "../../Components/InputError/InputError"
import patientLogin from "../../Assets/Login/patientLogin.png"
import PatientCareProgram from "../../Components/PatientCareProgram/PatientCareProgram"
import CelebrationIcon from "@mui/icons-material/Celebration"
import "../Login/Login.css"
import "../../Components/Forms/Forms.css"
import { useNavigate } from "react-router-dom"

export default function Landing() {
  const navigate = useNavigate()
  const [disableClick, setDisableClick] = useState(false)
  const [isCreated, setIsCreated] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  const [data, setData] = useState({
    agent_id: "",
    // name: '',
    first_name: "",
    last_name: "",
    birthdate: "",
    contact_no: "",
    email_address: "",
    address: "",
  })

  const [isError, setIsError] = useState({
    agent_id: false,
    first_name: false,
    last_name: false,
    birthdate: false,
    contact_no: false,
    address: false,
  })

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }

  function handleChange(e) {
    let newData = { ...data }
    const { name, value } = e.target
    newData[name] = value
    setData(newData)
  }

  async function handleCreate() {
    if (validateCreateAccount(data, setIsError) && !disableClick) {
      setDisableClick(true)
      const response = await createAccount(data)
      if (response.data) {
        setTimeout()
      } else {
        setDisableClick(false)
        toast.error(response.error.data.messages.error_messages[0])
      }
    }
  }

  return (
    <div className="row login-container bg-landing">
      <ToastContainer className={"toast-container pink-bg"} theme="colored" />

      <Row className="content-container">
        <Col md={6} className="p-5 text-left">
          <Badge className="patient-care" pill bg="success-custom">
            Patient Care Program
          </Badge>
          <div className="login-header-2 p-0">Patient Care Portal</div>
          <div>Avail discounts for your medicines.</div>
          {/* <Row className="mt-5">
            <Col md={12}>
              <label className="pin-error">
                You’ve entered incorrect pins too many times.{" "}
              </label>
              <br />
              <small>To try again, contact admin.</small>
            </Col>
            <Col md={6}>
              <button
                type="submit"
                className={"login-btn"}
                onClick={() => navigate("/login")}
              >
                RETURN
              </button>
            </Col>
          </Row> */}
          <Row className="mt-5">
            <Col md={6}>
              <button
                type="submit"
                className={"login-btn"}
                onClick={() => navigate("/login")}
              >
                LOGIN
              </button>
            </Col>
            <Col md={6}>
              <button
                type="submit"
                className={"register-btn"}
                onClick={() => navigate("/register")}
              >
                REGISTER
              </button>
            </Col>
          </Row>
        </Col>
        {/* <Col md={6}>
          <img
            src={"/assets/illustration-alt.png"}
            alt={"login-img"}
            className="login-img"
          />
        </Col> */}
      </Row>
    </div>
  )
}
