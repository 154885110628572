import React, { useState } from "react"
import { toast } from "react-toastify"

import { Row, Col, Form, InputGroup } from "react-bootstrap"
import { createAccount } from "../ ../../Helpers/API/CreateAccountApi/CreateAccountApi"
import { validateCreateAccount } from "../Helpers/Validations/CreateAccountValidation/CreateAccountValidation"

import { useNavigate } from "react-router-dom"
import InputError from "../Components/InputError/InputError"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { resetPassword } from "../Helpers/API/passwordAPI"
import ReactLoading from "react-loading"

export default function NewPassword() {
  const navigate = useNavigate()
  const [disableClick, setDisableClick] = useState(false)
  const [isCreated, setIsCreated] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [loading, setLoading] = useState(false)

  const [data, setData] = useState({
    password: "",
    confirm_password: "",
  })

  const [isError, setIsError] = useState({
    password: false,
    confirm_password: false,
    password_length: false,
  })

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }

  function handleChange(e) {
    let newData = { ...data }
    const { name, value } = e.target
    newData[name] = value
    setData(newData)
  }

  async function handleReset() {
    if (validateCreateAccount(data, setIsError) && !disableClick) {
      setDisableClick(true)
      setLoading(true)
      const response = await resetPassword(data)

      if (response.data) {
        localStorage.clear()
        navigate("/success-pass")
      } else {
        setDisableClick(false)
        setLoading(false)
        if (response.error.status === 500) {
          toast.error(`Error 500: ${response.error.data.message}`)
        } else {
          toast.error(response.error.data.messages.error_messages.message)
        }
      }
    }
  }

  return (
    <div className="row login-container bg-dashboard">
      <div className="content-container">
        <Row className=" mt-5 justify-content-center">
          <Col xs={12} md={6} className="p-5 mt-5">
            <Row className="forgot-password">
              <Col md={12}>
                <div className="forgot-label">Create New Password</div>
                <hr />{" "}
                <small>
                  <br />
                  Create a new password that is at least 6 characters long.{" "}
                  <br /> A strong password is combination of letters, numbers,
                  and punctuation marks.{" "}
                </small>
                <Form.Group className="mt-3 text-left mt-5">
                  <Form.Label className="h6" htmlFor="name">
                    New Password
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      name="password"
                      className="form-control"
                      onChange={handleChange}
                      // placeholder="Mobile Number"
                    />
                    <InputError
                      isValid={isError.password}
                      message={"This field is required."}
                    />
                    <InputError
                      isValid={isError.confirm_password}
                      message={"Passwords do not match."}
                    />
                    <InputError
                      isValid={isError.password_length}
                      message={
                        "Passwords should at least contain 6 characters."
                      }
                    />
                    <InputGroup.Text id="basic-addon1">
                      {" "}
                      <FontAwesomeIcon
                        icon={showPassword ? "fa-eye" : "fa-eye-slash"}
                        alt={"open"}
                        className={"max-menu"}
                        aria-hidden="true"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="mt-3 text-left mt-5">
                  <Form.Label className="h6" htmlFor="name">
                    Confirm New Password
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type={showNewPassword ? "text" : "password"}
                      name="confirm_password"
                      className="form-control"
                      onChange={handleChange}
                      // placeholder="Mobile Number"
                    />
                    <InputError
                      isValid={isError.password}
                      message={"This field is required."}
                    />
                    <InputError
                      isValid={isError.confirm_password}
                      message={"Passwords do not match."}
                    />
                    <InputError
                      isValid={isError.password_length}
                      message={
                        "Passwords should at least contain 6 characters."
                      }
                    />
                    <InputGroup.Text id="basic-addon1">
                      {" "}
                      <FontAwesomeIcon
                        icon={showNewPassword ? "fa-eye" : "fa-eye-slash"}
                        alt={"open"}
                        className={"max-menu"}
                        aria-hidden="true"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                      />
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Row className="justify-content-end mt-5">
                  <Col sm={12} md={6} lg={3}>
                    {" "}
                    <button
                      type="submit"
                      className={"login-btn code outlined"}
                      onClick={() => navigate("/login")}
                    >
                      Cancel
                    </button>
                  </Col>
                  <Col sm={12} md={6} lg={3}>
                    {" "}
                    {loading ? (
                      <button
                        type="submit"
                        className={"login-btn code"}
                        style={{ textAlign: "-webkit-center" }}
                      >
                        <ReactLoading
                          type={"spin"}
                          height={20}
                          width={25}
                          color="#fff"
                        />
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className={"login-btn code"}
                        onClick={handleReset}
                      >
                        Submit
                      </button>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>{" "}
      </div>
    </div>
  )
}
