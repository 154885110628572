import React, { useState, useEffect } from "react"
import { Row, Col, Form, InputGroup, Badge } from "react-bootstrap"
import { validateCreateAccountPrimary } from "../../Helpers/Validations/CreateAccountValidation/CreateAccountValidation"
import InputError from "../../Components/InputError/InputError"
import "../Login/Login.css"
import "../../Components/Forms/Forms.css"
import { useNavigate } from "react-router-dom"
import BackButton from "../../Components/Back/BackButton"
import pdf from "../../Assets/Privacy/data_privacy_act.pdf"
import { checkQR } from "../../Helpers/API/CreateAccountApi/CreateAccountApi"
import { ToastContainer, toast } from "react-toastify"

export default function Register({ navigation, details, setDetails }) {
  const navigate = useNavigate()
  const [enableClick, setEnableClick] = useState(false)

  const [isError, setIsError] = useState({
    ecard_no: false,
    name: false,
    contact_no: false,
    contact_no_length: false,
  })

  async function handleNext() {
    if (validateCreateAccountPrimary(details, setIsError)) {
      const response = await checkQR({ ecard_no: details.ecard_no })

      if (response.data) {
        let details = response.data

        toast.success(details.message)
        setTimeout(() => {
          navigation.next()
        }, 2000)
      } else {
        if (response?.error?.status === 500) {
          toast.error(`Error 500: ${response.error.data.message}`)
        } else {
          toast.error(response.error.data.messages.error_messages.message)
        }
      }
    }
  }

  const handleCheckboxChange = () => {
    setDetails({ ...details, is_agree: !details.is_agree })
  }

  function handleChange(e) {
    let newData = { ...details }
    const { name, value } = e.target

    newData[name] = value

    setDetails(newData)
  }

  const handleKeyPress = (event) => {
    const { key } = event
    // Allow alphanumeric characters and prevent others
    if (!/^[a-zA-Z\s]*$/.test(key)) {
      event.preventDefault()
    }
  }

  useEffect(() => {
    if (details.is_agree === true) {
      setEnableClick(true)
    } else {
      setEnableClick(false)
    }
  }, [details])

  return (
    <div className="row register-container bg-register">
      <ToastContainer theme="colored" />
      <div className="mt-5">
        {" "}
        <BackButton
          onClick={() => {
            navigation.previous()
            setDetails({ ...details, ecard_no: "", contact_no: "", name: "" })
          }}
        />
      </div>
      <div className="content-container">
        <Row className=" justify-content-center">
          <Col xs={12} className="mb-5"></Col>
          <Col sm={12} md={6} className="mt-5 p-5 pb-0 pt-0 text-left">
            <small className="text-left">WELCOME TO</small>
            <div className="text-left login-header-2  p-0 ">Patient Care</div>
          </Col>
          <Col sm={12} md={6} className="p-5 text-center">
            <Row className="login-div justify-content-center">
              <Col xs={12}>
                <div className="text-left">
                  Create your account. Quick and easy.
                </div>
                <Form.Group className="mt-3 text-left">
                  <Form.Label className="h6" htmlFor="name">
                    E-Card No.
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      name="ecard_no"
                      className="form-control ecard disabled"
                      onChange={handleChange}
                      value={details.ecard_no}
                      disabled={details.is_scanned}
                      onKeyPress={(e) => {
                        // Prevent entering "-" character
                        if (/^\d+$/.test(e.key) === false) {
                          e.preventDefault()
                        }
                      }}
                    />
                    <InputError
                      isValid={isError.ecard_no}
                      message={"This field is required"}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group className="mt-3 text-left">
                  <Form.Label className="h6" htmlFor="name">
                    Name
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      name="name"
                      className="form-control"
                      onChange={handleChange}
                      value={details.name}
                      onKeyPress={handleKeyPress}
                    />
                    <InputError
                      isValid={isError.name}
                      message={"This field is required"}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group className="mt-3 text-left">
                  <Form.Label className="h6" htmlFor="name">
                    Contact Number
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>+63</InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="contact_no"
                      className="form-control"
                      onChange={handleChange}
                      value={details.contact_no}
                      maxLength={10}
                      onKeyPress={(e) => {
                        // Prevent entering "-" character
                        // if (/^\d+$/.test(e.key) === false) {
                        //   e.preventDefault()
                        // }
                        if (
                          /^\s*$/.test(e.key) ||
                          /^\d+$/.test(e.key) === false
                        ) {
                          e.preventDefault()
                        }
                      }}
                    />
                    <InputError
                      isValid={isError.contact_no}
                      message={"This field is required"}
                    />
                    <InputError
                      isValid={isError.contact_no_length}
                      message={"Contact number should contain 10 digits."}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group className="mt-3 text-left">
                  <input
                    id="agree"
                    type="checkbox"
                    checked={details.is_agree}
                    onChange={handleCheckboxChange}
                  />
                  <span htmlFor="agree">
                    {" "}
                    By clicking Proceed, you confirm that you accept our{" "}
                    <a
                      onClick={() => window.open(pdf)}
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontWeight: "bold",
                      }}
                    >
                      Privacy Policy
                    </a>
                    .
                  </span>
                  {/* <span className="terms-and-conditions">
                  terms and conditions.
                </span> */}
                </Form.Group>
              </Col>
              <Col md={12}>
                <button
                  type="submit"
                  className={`login-btn ${enableClick ? "" : "disabled"}`}
                  onClick={handleNext}
                  disabled={!enableClick}
                >
                  PROCEED
                </button>
                <small>
                  Already have an account?{" "}
                  <strong
                    onClick={() => {
                      navigate("/login")
                    }}
                  >
                    Login
                  </strong>
                </small>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  )
}
