import "./App.css"
import "./Assets/FontAwesome/index"
import "react-toastify/dist/ReactToastify.css"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

// import Login from "./Pages/Login/Login";
import CreateAccount from "./Pages/CreateAccount/CreateAccount"
import OTP from "./Pages/Users/OTP"
import Dashboard from "./Pages/Dashboard/Dashboard"
import VerifyPromoCode from "./Components/Wallet/VerifyPromoCode"
import Wallet from "./Components/Wallet/Wallet"
import VerifyOR from "./Components/Wallet/VerifyOR"
import Landing from "./Pages/Users/Landing"
import Login from "./Pages/Users/Login"
import Register from "./Pages/Users/Register"
import RegisterCont from "./Pages/Users/RegisterCont"
import FormSuccess from "./Pages/Users/Success"
import LoginQR from "./Pages/Users/LoginQR"
import RegisterQR from "./Pages/Users/RegisterQR"
import SuccessPass from "./Pages/Users/SuccessPass"
import InvalidCode from "./Pages/Users/InvalidCode"
import ForgotPassword from "./Pages/ForgotPassword"
import NewPassword from "./Pages/NewPassword"
import ViewProduct from "./Pages/Products/ViewProducts"
import Products from "./Pages/Products/Products"
import SwitchRegister from "./Pages/Users/SwitchRegister"
import { useEffect, useState } from "react"
import {
  getToken,
  getTokenExpiry,
  refreshPage,
  removeUserSession,
} from "./Helpers/Utils/Common"
import { ToastContainer, toast } from "react-toastify"
import SwitchLogin from "./Pages/Users/SwitchLogin"

function App() {
  const [token, setAuthentication] = useState(getToken())
  const tokenExpiry = getTokenExpiry()

  function promptExpiry() {
    toast.warning("TOKEN HAS EXPIRED. PLEASE LOG IN AGAIN...")
    setTimeout(() => {
      removeUserSession()
      refreshPage()
    }, 5000)
  }

  useEffect(() => {
    var startDate = new Date().getTime()
    var endDate

    if (tokenExpiry != null) {
      endDate = new Date(tokenExpiry.replace(/['"]+/g, ""))

      var seconds = Math.floor((endDate - startDate) / 1000)
      setInterval(promptExpiry, parseFloat(seconds) * 1000)
    }
  }, [])


  console.log("token",token)
  return (
    <div className="App">
      <ToastContainer theme="colored"/>
      <Router>
        <Routes>
          <Route path="*" element={<Landing />} />
          <Route path="/" element={<Landing />} />
          <Route path="/login" element={<SwitchLogin/>} />
          <Route path="/register" element={<SwitchRegister />} />

          <Route path="/dashboard" element={token ? <Products />:<Landing/>} />
           <Route path="/view-product/:productID" element={token ? <ViewProduct />: <Landing/>} />
          <Route path="/register/success" element={<FormSuccess/>} />
          <Route path="/success-pass" element={<SuccessPass />} />
          <Route path="/invalid-code" element={<InvalidCode />} />
          <Route path="/forget-password" element={<ForgotPassword />} />
          <Route path="/confirm-otp" element={<OTP />} />
          <Route path="/reset-password" element={<NewPassword />} />
          <Route path="/products/:promoID" element={<Products />} />
         

          <Route path="/wallet" element={<Wallet />} />
          <Route path="/verify-promo" element={<VerifyPromoCode />} />
          <Route path="/verify-or" element={<VerifyOR />} />

          {/* <Route path="/create-account" element={<CreateAccount />} /> */}
        </Routes>
      </Router>
    </div>
  )
}

export default App
