import React, { useState, useRef } from "react"
import { Row, Col, Modal } from "react-bootstrap"

//Images
import warning from "../../Assets/Modal/warning.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
//css
import "../../Pages/Modal/Modal.css"
import payment from "../../Assets/Images/payment.png"
import MYTSelect from "../Forms/MYTSelect"
import MYTNumberField from "../Forms/MYTNumberField"
import MYTTextField from "../Forms/MYTTextField"
import MYTCreateBtn from "../Buttons/MYTCreateBtn"
import MYTRadioButton from "../Forms/MYTRadioButton"

const ModalPopUp = ({ type, show, handleClose, data, handleDelete, text }) => {
  const showHideClassName = show
    ? "modal-pop display-block"
    : "modal-pop display-none"

  if (type === "delete") {
    return (
      <div>
        <Modal show={show} onHide={handleClose} size="md" centered>
          {/* <Modal.Header/> */}
          <Modal.Body className="delete-modal">
            <Row className="mt-4">
              <h2 className="d-flex justify-content-center">
                <FontAwesomeIcon icon={faTrash} className="warning" />
              </h2>
            </Row>

            <Row className="mt-2 warning-text justify-content-center">
              Are you sure you want to
            </Row>
            <Row className="mt-2 warning-text justify-content-center">
              delete this {text}?
            </Row>

            <Row className="mt-5 mb-4">
              <Col xs={6} className="d-flex justify-content-end">
                <button className="button-warning me-3" onClick={handleClose}>
                  Cancel
                </button>
              </Col>
              <Col xs={6} className="">
                <button className="button-warning-fill" onClick={handleDelete}>
                  Delete
                </button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    )
  }

  if (type === "warning") {
    return (
      <div className={showHideClassName}>
        <section className="modal-mains modal-reset">
          <div className="modal-cont-header d-flex justify-content-center">
            <div className="modal-header-label">DELETE</div>
          </div>
          <hr className="modal-line" />
          <div className="modal-content-body">
            <Row className="">
              <Row className="mt-4 mb-4 center">
                <img src={warning} className="warning-icon"></img>
                <label className="label">
                  Warning! You are not allowed to continue processing.
                </label>
                <textarea value={data}></textarea>
              </Row>
              <hr className="modal-line" />
              <div className="mt-2 right d-flex justify-content-end">
                <button
                  type="button"
                  className="cancel-btn ms-0"
                  onClick={handleClose}
                >
                  CLOSE
                </button>
              </div>
            </Row>
          </div>
        </section>
      </div>
    )
  }

  if (type === "Base64") {
    return (
      <div className={showHideClassName}>
        <section className="modal-mains modal-reset">
          <div className="modal-cont-header d-flex justify-content-center">
            <div className="modal-header-label">CONVERTED</div>
          </div>
          <hr className="modal-line" />
          <div className="modal-content-body">
            <Row className="">
              <Row className="mt-4 mb-4 center">
                <textarea className="text-area" value={data}></textarea>
              </Row>
              <hr className="modal-line" />
              <div className="mt-2 right d-flex justify-content-end">
                <button
                  type="button"
                  className="cancel-btn ms-0"
                  onClick={handleClose}
                >
                  CLOSE
                </button>
              </div>
            </Row>
          </div>
        </section>
      </div>
    )
  }

  if (type === "edit") {
    return (
      <div className={showHideClassName}>
        <section className="modal-mains modal-reset">
          <div className="modal-cont-header d-flex justify-content-center">
            <div className="modal-header-label">UPDATE</div>
          </div>
          <hr className="modal-line" />
          <div className="modal-content-body">
            <Row className="">
              <Row className="mt-4 mb-4 center">
                <label className="label">
                  ASA NAMAN ANG FORMS BAN HAHAHAH.
                </label>
              </Row>
              <hr className="modal-line" />
              <div className="mt-2 right d-flex justify-content-end">
                <button type="button" className="add-btn me-2">
                  EDIT
                </button>
                <button
                  type="button"
                  className="cancel-btn ms-0"
                  onClick={handleClose}
                >
                  CLOSE
                </button>
              </div>
            </Row>
          </div>
        </section>
      </div>
    )
  }

  if (type === "view") {
    return (
      <div className={showHideClassName}>
        <section className="modal-mains modal-reset">
          <div className="modal-cont-header d-flex justify-content-center">
            <div className="modal-header-label">VIEW</div>
          </div>
          <hr className="modal-line" />
          <div className="modal-content-body">
            <Row className="">
              <Row className="mt-4 mb-4 center">
                <label className="label">BEST FRIENDS CHECK!</label>
              </Row>
              <Row className="mt- mb-4 center">
                <Col xs="5 left">
                  <label className="label ms-5">NAME</label>
                </Col>
                <Col xs="3 center">
                  <label className="label">AGE</label>
                </Col>
                <Col xs="4 center">
                  <label className="label">STATUS</label>
                </Col>
              </Row>
              <Row className="mt- mb-4 center">
                <Col xs="5 left">
                  <label className="label ms-5">Novel Palconit</label>
                </Col>
                <Col xs="3 center">
                  <label className="label">23</label>
                </Col>
                <Col xs="4 center">
                  <label className="label">Single</label>
                </Col>
              </Row>
              <Row className="mt- mb-4 center">
                <Col xs="5 left">
                  <label className="label ms-5">Vanessa Ibale</label>
                </Col>
                <Col xs="3 center">
                  <label className="label">22</label>
                </Col>
                <Col xs="4 center">
                  <label className="label">Taken</label>
                </Col>
              </Row>
              <Row className="mt- mb-4 center">
                <Col xs="5 left">
                  <label className="label ms-5">Donna Cuesta</label>
                </Col>
                <Col xs="3 center">
                  <label className="label">23</label>
                </Col>
                <Col xs="4 center">
                  <label className="label">Single</label>
                </Col>
              </Row>
              <Row className="mt- mb-4 center">
                <Col xs="5 left">
                  <label className="label ms-5">Lalaine Labadan</label>
                </Col>
                <Col xs="3 center">
                  <label className="label">23</label>
                </Col>
                <Col xs="4 center">
                  <label className="label">Taken</label>
                </Col>
              </Row>
              <hr className="modal-line" />
              <div className="mt-2 right d-flex justify-content-end">
                <button
                  type="button"
                  className="cancel-btn ms-0"
                  onClick={handleClose}
                >
                  CLOSE
                </button>
              </div>
            </Row>
          </div>
        </section>
      </div>
    )
  }

  if (type === "payment") {
    return (
      <div className={showHideClassName}>
        <section className="modal-mains modal-reset">
          <Row className="justify-content-center">
            <Col xs={4} className="text-center">
              <img src={payment} alt="payment" width="60" height="50" />
            </Col>
            <Col className="text-center">
              <h5 className="bg-purple mt-3 text-left">PAYMENT METHOD</h5>
            </Col>
          </Row>

          <div className="modal-content-body">
            <Row className="mt-5 justify-content-center">
              <Col xs={12} className="text-center">
                <MYTSelect
                  required
                  type={`block`}
                  formLabel={`PAYMENT METHOD`}
                  name="PAYMENT METHOD"
                  size={`sm`}
                  options={[]}
                  // isError={isError.role}
                  // onChange={handleAddChange}
                />
              </Col>
              <Col xs={6}>
                <MYTTextField
                  type={`block`}
                  formLabel={`NAME OF CLAIMANT`}
                  size={`sm`}
                  placeholder={``}
                  // onChange={handleAddChange}
                />
              </Col>
              <Col xs={6}>
                <MYTNumberField
                  type={`block`}
                  formLabel={`CONTACT NUMBER`}
                  size={`sm`}
                  placeholder={`09xxxxxxxxx`}
                  sideInput
                  sideInputType={`string`}
                  sideInputValue={`+63`}
                />
              </Col>
              <Col xs={12}>
                <MYTRadioButton
                  type={`block`}
                  formLabel={``}
                  name="gender"
                  labels={[{ label: "Use my information" }]}
                  // onChange={handleAddChange}

                  // isError={isError.gender}
                />
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={10} sm={4} className="text-center">
                <MYTCreateBtn
                  size={`sm`}
                  onClick={handleClose}
                  btnText={"SAVE"}
                  // isClicked={isClicked}
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    )
  }
}

export default ModalPopUp
