import React, { useState, useEffect } from "react"
import { ToastContainer, toast } from "react-toastify"
import ReactLoading from "react-loading"
import { Row, Col, Container, Form, InputGroup, Badge } from "react-bootstrap"
import { createAccount } from "../../Helpers/API/CreateAccountApi/CreateAccountApi"
import { validateCreateAccount } from "../../Helpers/Validations/CreateAccountValidation/CreateAccountValidation"
import InputError from "../../Components/InputError/InputError"
import patientLogin from "../../Assets/Login/patientLogin.png"
import PatientCareProgram from "../../Components/PatientCareProgram/PatientCareProgram"
import CelebrationIcon from "@mui/icons-material/Celebration"
import "../Login/Login.css"
import "../../Components/Forms/Forms.css"
import { useNavigate } from "react-router-dom"
import QRCode from "react-qr-code"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import BackButton from "../../Components/Back/BackButton"
import QrReader from "react-qr-scanner"

export default function LoginQR({ navigation, details, setDetails }) {
  const navigate = useNavigate()
  const [disableClick, setDisableClick] = useState(false)
  const [isCreated, setIsCreated] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [scannerDetails, setScannerDetails] = useState({
    delay: 1000,
    result: "",
  })
  const [mode, setMode] = useState("rear")

  const [data, setData] = useState({
    agent_id: "",
    // name: '',
    first_name: "",
    last_name: "",
    birthdate: "",
    contact_no: "",
    email_address: "",
    address: "",
  })

  const [isError, setIsError] = useState({
    agent_id: false,
    first_name: false,
    last_name: false,
    birthdate: false,
    contact_no: false,
    address: false,
  })

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }

  function handleChange(e) {
    let newData = { ...data }
    const { name, value } = e.target
    newData[name] = value
    setData(newData)
  }

  async function handleCreate() {
    if (validateCreateAccount(data, setIsError) && !disableClick) {
      setDisableClick(true)
      const response = await createAccount(data)
      if (response.data) {
        setTimeout()
      } else {
        setDisableClick(false)
        toast.error(response.error.data.messages.error_messages[0])
      }
    }
  }

  const handleError = (err) => {
    console.log("eroor", err)
  }
  const handleScan = (data) => {
    if (data !== null) {
      setScannerDetails({ ...scannerDetails, result: data.text })
      setDetails({ ...details, ecard_no: data.text, is_scanned: true })
      setTimeout(() => {
        navigation.next()
      }, 2000)
    }
  }

  return (
    <div className="row login-container  bg-qr">
      <div className="mt-5">
        <BackButton
          onClick={() => {
            navigate("/")
          }}
        />
        <Row className="justify-content-center">
          <Col xs={12} md={12} lg={6} className="text-center mt-5">
            {scannerDetails.result === "" ? (
              <div className="qr-container">
                <div className="font-large p-3">Scan QR to Login</div>{" "}
                {/* <select onChange={(e) => setMode(e.target.value)}>
                  <option value={"rear"}>Back Camera</option>
                  <option value={"front"}>Front Camera</option>
                </select> */}
                <div
                  style={{
                    height: "250px",
                    margin: "0 auto",
                    width: "100%",
                  }}
                >
                  <QrReader
                    // legacyMode={true}
                    constraints={{
                      video: { facingMode: "environment" },
                    }}
                    delay={scannerDetails.delay}
                    style={{
                      width: "200px", // Ensures that QR reader width is full within its container
                      height: "auto", // Maintains aspect ratio but can be adjusted as needed
                      maxWidth: "300px", // Limiting the maximum width can help on larger screens
                    }}
                    onError={handleError}
                    onScan={handleScan}
                  />
                  {/* <QrReader
                    facingMode={mode}
                    delay={scannerDetails.delay}
                    style={{
                      height: 240,
                    }}
                    onError={handleError}
                    onScan={handleScan}
                  /> */}
                </div>
              </div>
            ) : (
              <div className="qr-container">
                <div className="font-large p-3">Please wait... </div>{" "}
                <div
                  style={{
                    height: "250px",
                    margin: "0 auto",
                    width: "100%",
                    textAlign: "-webkit-center",
                  }}
                >
                  <ReactLoading
                    type="spin"
                    color="var(--primary-color)"
                    height={240}
                    width={200}
                  />
                </div>
              </div>
            )}

            <Col md={12}>
              <button
                type="submit"
                className={"login-btn"}
                onClick={() => {
                  setDetails({ ...details, is_scanned: false })
                  navigation.next()
                }}
              >
                Proceed to Form
              </button>
            </Col>
          </Col>
        </Row>
      </div>
      {/* <Row className="content-container"> */}

      {/* </Row> */}
    </div>
  )
}
