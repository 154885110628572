import React, { useState } from "react"
import { Row, Col, Button } from "react-bootstrap"

//CSS
import "../Dashboard/Dashboard.css"
//Images
import User from "../../Assets/Dashboard/user.png"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate } from "react-router-dom"
import { refreshPage, removeUserSession } from "../../Helpers/Utils/Common"
import { logoutUser } from "../../Helpers/API/authApi"

function NavbarTop() {
  const navigate = useNavigate()

  async function handleLogout() {
    const response = await logoutUser()
    if (response) {
      localStorage.clear()

      setTimeout(() => {
        navigate("/")
        refreshPage()
      }, 500)
    }
  }
  return (
    <div className="sidebar">
      <div className="row justify-content-between p-3">
        <div
          className="col-12 col-md-6 navbar-content"
          style={{ fontWeight: "bold", color: "var(--primary-color)" }}
        >
          Patient Care Program
        </div>
        <div className="col-12 col-md-6 navbar-content">
          <div className="row content-row">
            <div className="col-sm-6 col-md-4">
              <button
                type="submit"
                className={"logout-button"}
                onClick={handleLogout}
              >
                LOGOUT
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NavbarTop
