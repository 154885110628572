import React, { useState } from "react"
import { Row, Col, Table, Form, Button } from "react-bootstrap"

import "./VerifyPromoCode.css"

import back from "../../Assets/Images/back-arrow.png"
import wallet from "../../Assets/Images/wallet-card.png"
import { useNavigate } from "react-router-dom"
import NavbarTop from "../../Pages/NavbarTop/NavbarTop"
import ModalPopUp from "../Modals/Modals"
import MYTDateField from "../Forms/MYTDateField"
import MYTCreateBtn from "../Buttons/MYTCreateBtn"

export default function Wallet() {
  const navigate = useNavigate()
  const [showModalPayment, setShowModalPayment] = useState(false)
  return (
    <div className="page dashboard">
      <NavbarTop />
      <div className={` container inactive"}`}>
        <Row className="mt-4 justify-content-between">
          <Col xs="4">
            <div className="wallet-card-cont">
              <img src={wallet} alt="wallet" className="wallet-img" />

              <div className="wallet-centered">
                <h1 className="wallet-num">{`+63  923  093  5923`}</h1>
              </div>
              <div className="wallet-bottom-left text-left">
                <h5>
                  <strong>JUAN DE LA CRUZ</strong>
                </h5>
                TOTAL CASHBACK AMOUNT
              </div>

              <div className="wallet-bottom-right">
                <strong>P 300,000.00</strong>
              </div>
            </div>
          </Col>
          <Col xs="8">
            <div className="wallet-card mt-3">
              <Row className="justify-content-between p-4">
                <Col xs={6} className="text-left pt-1 bg-purple">
                  <strong>MY WALLET</strong>
                </Col>
                <Col xs={4}>
                  <Form.Control
                    type="date"
                    size={"sm"}
                    // name={name}
                    // value={value}
                    // onChange={onChange}
                  />
                </Col>
                <Col xs={6} className="mt-5">
                  <subtitle className="bg-purple">
                    <strong>Cashback Rewards Earned</strong>
                  </subtitle>
                  <h2>
                    P 500.00{" "}
                    <Button size="sm" className="claim-btn">
                      Claim
                    </Button>
                  </h2>
                </Col>
                <Col xs={6} className="mt-5">
                  <subtitle className="bg-purple">
                    <strong>Cashback Under Review</strong>
                  </subtitle>
                  <h2>P 500.00</h2>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col xs={12}>
            <h4 className="label-text text-left">
              <strong>TRANSACTION HISTORY</strong>
            </h4>
          </Col>
          <Col xs={12}>
            <Table>
              <thead>
                <tr style={{ color: "var(--primary-color)" }}>
                  <th>STATUS</th>
                  <th>OR NO</th>
                  <th>MEDICINE</th>
                  <th>CASHBACK/PC</th>
                  <th>QUANTITY</th>
                  <th>TOTAL CASHBACK/PC</th>
                  <th>TOTAL CASHBACK RECEIVED</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <strong>PENDING</strong>
                  </td>
                  <td>12345</td>
                  <td>BIOGESIC</td>
                  <td>P 30.00</td>
                  <td>5</td>
                  <td>P 150.00</td>
                  <td>P 150.00</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <ModalPopUp
        type="payment"
        show={showModalPayment}
        handleClose={() => setShowModalPayment(false)}
      />
    </div>
  )
}
