import React, { useState, useEffect } from "react"
import { ToastContainer, toast } from "react-toastify"
import ReactLoading from "react-loading"
import { Row, Col, Container, Form, InputGroup, Badge } from "react-bootstrap"
import { createAccount } from "../../Helpers/API/CreateAccountApi/CreateAccountApi"
import { validateCreateAccount } from "../../Helpers/Validations/CreateAccountValidation/CreateAccountValidation"
import InputError from "../../Components/InputError/InputError"
import patientLogin from "../../Assets/Login/patientLogin.png"
import PatientCareProgram from "../../Components/PatientCareProgram/PatientCareProgram"
import CelebrationIcon from "@mui/icons-material/Celebration"
import "../Login/Login.css"
import "../../Components/Forms/Forms.css"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function InvalidCode() {
  const navigate = useNavigate()
  const [disableClick, setDisableClick] = useState(false)
  const [isCreated, setIsCreated] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  const [data, setData] = useState({
    agent_id: "",
    // name: '',
    first_name: "",
    last_name: "",
    birthdate: "",
    contact_no: "",
    email_address: "",
    address: "",
  })

  const [isError, setIsError] = useState({
    agent_id: false,
    first_name: false,
    last_name: false,
    birthdate: false,
    contact_no: false,
    address: false,
  })

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }

  function handleChange(e) {
    let newData = { ...data }
    const { name, value } = e.target
    newData[name] = value
    setData(newData)
  }

  async function handleCreate() {
    if (validateCreateAccount(data, setIsError) && !disableClick) {
      setDisableClick(true)
      const response = await createAccount(data)
      if (response.data) {
        setTimeout()
      } else {
        setDisableClick(false)
        toast.error(response.error.data.messages.error_messages[0])
      }
    }
  }

  return (
    <div className="row login-container bg-register">
      <div className="content-container ">
        <Row className="mt-5 justify-content-center">
          <Col xs={12} md={4} className="mt-5 p-5">
            <Row
              style={{
                marginTop: "100px",
                // border: "1px solid grey",
                padding: "40px",
                borderRadius: "15px",
                background: "white",
                boxShadow: "2px 2px 4px #c1c1c1",
              }}
            >
              <Col md={12}>
                {" "}
                <img
                  src={"/assets/error.png"}
                  alt={"party-img"}
                  className="text-center"
                  width={50}
                  height={50}
                />
                <div className="login-invalid centered p-0 text-center mt-2">
                  Invalid Code!
                </div>
              </Col>
              <Col md={12}>
                <button
                  type="submit"
                  className={"login-btn"}
                  onClick={() => navigate("/login")}
                >
                  RETURN
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  )
}
