import { postAPICall } from "./axiosMethodCalls"
import { getUser, getKey, getToken, refreshPage } from "../Utils/Common"

/***************************
 * LOGIN
 ***************************/

export const loginUser = async (username, password, ipAddress) => {
  try {
    const response = await postAPICall(process.env.REACT_APP_LINK + "login", {
      username: username,
      password: password,
      role: "patient",
      ip_address: ipAddress,
    })
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

/***************************
 * LOGOUT
 ***************************/

export const logoutUser = async () => {
  try {
    const response = await postAPICall(process.env.REACT_APP_LINK + "logout", {
      requester: getUser(),
      token: getToken().replace(/['"]+/g, ""),
    })
    // localStorage.clear()
    
    // refreshPage()

    return response
  } catch (error) {
    //  localStorage.clear()
    // refreshPage()
    return { error: error.response }
  }
}
