import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom"

export default function BackButton({ onClick }) {
  const navigate = useNavigate()

  const handleBack = () => {
    navigate(-1)
  }
  return (
    <Row style={{ paddingLeft: "3%" }} className="back-row">
      <Col md={12} className="text-left" style={{ cursor: "pointer" }}>
        <div onClick={onClick ? onClick : handleBack}>
          <FontAwesomeIcon
            icon={"fa-arrow-left"}
            alt={"caret-up"}
            aria-hidden="true"
            className="caret-icon align-self-center"
          />{" "}
          Back
        </div>
      </Col>
    </Row>
  )
}
